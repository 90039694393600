import moment from "moment";
import React, {
  ChangeEvent,
  Fragment,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dimmer,
  Dropdown,
  DropdownProps,
  Input,
  InputOnChangeData,
  Loader,
  Segment,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { assignMeetingQueue } from "../../../assets/api";
import InputDate from "../../../components/InputDate";
import BOTableMeeting from "../../../components/ServicesDetails/BOServiceMeeting/BOTableMeeting";
import { CONTENT_MEETING_TYPE } from "../../../components/ServicesDetails/BOServiceMeeting/BOTableMeeting/components/BOTableMeeting/type/contentMeeting";
import { AuthContext } from "../../../context/AuthContext";
import { MeetingListType } from "../../../types/dashboard";
import { MeetingList } from "./MeetingList";

interface MeetingContainerProps {
  codeArea: string;
  codeService: string;
  arrayMeeting: MeetingListType[];
  handleGetNextEvent: Function;
  handleRefresh: Function;
  // refreshAssignedMeetingList: Function;
}
export enum EnumSearchOption {
  ALL = "All",
  DNI = "DNI",
  CodeMeeting = "code",
}

export enum EnumSearchPlaceholder {
  DNI = "home.search.placeholder.dni",
  CodeMeeting = "home.search.placeholder.meeting",
}

const MeetingContainer: React.FC<MeetingContainerProps> = ({
  codeArea,
  codeService,
  arrayMeeting,
  handleGetNextEvent,
  handleRefresh,
}: // refreshAssignedMeetingList,
MeetingContainerProps) => {
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [newArrayMeeting, setNewArrayMeeting] = useState<any[]>(arrayMeeting);

  const userCode = authContext.account.profile.code;

  useEffect(() => {
    setNewArrayMeeting(arrayMeeting);
  }, [arrayMeeting]);

  const handleRefreshBTN = (date?: string) => {
    const currentDate = moment(newArrayMeeting[0]?.start || new Date()).format(
      "YYYYMMDD"
    );
    (async () => {
      setLoading(false);
      await handleRefresh(codeService, codeArea, date || currentDate);
      setLoading(true);
    })();
  };

  async function selectEvent(meeting: any) {
    const meetingID = meeting.code;

    handleGetNextEvent(
      { ...meeting, meetingId: meetingID },
      (areaCode: any, meetingId: any, code: any, token: any) => {
        return assignMeetingQueue(codeArea, meetingId, code, token)
      }
    );
  }


  const contentMeeting = [
    CONTENT_MEETING_TYPE.START,
    CONTENT_MEETING_TYPE.CODE,
  ];

  const formatedMeettings = newArrayMeeting.map((_) => ({
    ..._,
    dni: _.attendees ? _.attendees[0]?.personId : "",
  }));

  return (
    <Fragment>
      <h2>{t("home.unassigned_meetings")} </h2>
      
      {loading ? (
        
        <BOTableMeeting
          meetings={formatedMeettings}
          contentMeeting={contentMeeting}
          onClickMeeting={selectEvent}
          servicesRefresh={handleRefreshBTN}
        />
      ) : (
        <Segment padded={"very"} size={"massive"}>
          <Dimmer active inverted>
            <Loader size="medium">{t("loading.defect")}</Loader>
          </Dimmer>
        </Segment>
      )}
    </Fragment>
  );
};
export default MeetingContainer;
