/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getNextQueue,
  getQueueStatus,
  getUserServices,
} from "../../../../assets/api";
import { AuthContext } from "../../../../context/AuthContext";
import { AuthorizationModel } from "../../../../models/Authorization";
import {
  defaultQueue,
  Queue,
} from "../../../../types/dashboard";
import { renderList, UserService } from "../../Dashboard";
import Screen from "./Screen";
import { toast } from "react-toastify";
import {
  getEvent,
  getEventsWithServiceDay,
} from "../../../../assets/events";

import { EnumMeetingStatus } from "../../../../enum/EnumMeetingStatus";
import moment from "moment";

interface Props {
  service: any;
  setAttendee: (x: any) => void;
  setService: (x: any) => void;
  setUsersArea: (x: any) => void;
  setQueueMeeting: (x: any) => void;
  setStartMeeting: (x: any) => void;
  setTypeButtonList: (x: any) => void;
  setQueueCounter: (x: any) => void;
  setUserList: (x: any, y?: string) => void;
  discardCancelledMeetings: (x: any, y?: any) => void;
  setMeetingsAssignedList: (x: any) => void;
  serviceSelected: any;
  referenceUpdate?: (x: any) => void;
}

interface ISelect {
  key: string;
  content: string;
  text: string;
}
const defaultSelectServices: ISelect[] = [
  {
    key: "",
    content: "",
    text: "",
  },
];

export default (props: Props) => {
  const TypeButtonList = {
    DASHBOARD: "DASHBOARD",
    MEETING_CREATED: "MEETING_CREATED",
    SERVICE: "SERVICE",
  };
  const [finish, setFinish] = useState(false);
  const [services, setServices] = useState<UserService[]>([]);
  const [servicesWithQueue, setServicesWithQueue] = useState<renderList[]>([]);

  const [queue, setQueue] = useState<Queue[]>([defaultQueue]);
  const [selectServices, setSelectServices] = useState<ISelect[]>(
    defaultSelectServices
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;
  const { t } = useTranslation();

  useEffect(() => {
    
    initData();
  }, []);

  useEffect(() => {
    setSelectServices([]);

    services.map((service) => {
      const select = {
        key: service.code,
        text: service.displayName,
        content: service.displayName,
      };

      setSelectServices((service) => [...service, select]);
    });
  }, [services, setServices]);

  const refreshServicesList = async () => {
    await refreshMeetingAssigned();
  };

  const getQueueData = async (
    codeService: string,
    codeArea: string
  ) => {
    try {
      const { shared } = await authContext.getTokenForScopes();
      const responseQueue = await getQueueStatus(codeService, codeArea, shared);
      return responseQueue;
    } catch (error) {
     console.error(error)
    }
  };

  const getNumberMeetings = async (data: Queue[], service: any) => {
    let count = 0;

    const token = authContext.account.access_token;

    const form = {
      service: service.code,
    };

    const events = await getEventsWithServiceDay(form, token);

    data
      .filter((_) => props.discardCancelledMeetings(_, events))
      .forEach((d: Queue) => {
        if (d.isVisibleToServants && d.assignedTo === "NotAssigned") {
          count++;
        }
      });

    return count;
  };

  const getDataServicesWithQueue = async (dataServices: UserService[]) => {
    return await Promise.all(
      dataServices.map(async (service: UserService) => {
        const data: any = await getQueueData(service.code, service.areaCode);
        if(data instanceof Object){
          data.map((event: any) => {
            const start = moment(new Date(event.meetingStart)).add(-10, 'minutes').toDate()
            const end = moment(new Date(event.meetingStart)).add(30, 'minutes').toDate();
            const minutesFromNow = moment().toDate();

            event.MeetingEnd = minutesFromNow < end && minutesFromNow > start;
          })
        }

        let count = (data instanceof Object ? data.filter((event: any) => event.assignedTo === "NotAssigned" && event.MeetingEnd).length : 0);

        const render: renderList = { service: service, count };
        return render;
      })
    );
  };

  const initData = async () => {
    await refreshMeetingAssigned();
    props.referenceUpdate && props.referenceUpdate(refreshMeetingAssigned);
  };

  const updateMeetingAssigned = async (dataServices: UserService[]) => {
    const { backoffice, shared } = await authContext.getTokenForScopes();
    let totalMeetingsAssigned: any[] = [];

    await Promise.all(
      dataServices.map(async (service: UserService) => {
        const account: AuthorizationModel = await authContext
          .getAccount()
          .then((response) => JSON.parse(response));
        const { shared } = await authContext.getTokenForScopes();
        const name = account.profile.code;
        const data: any = await getQueueData(service.code, service.areaCode);
       
        
        const callback: any = async (d: Queue, service: UserService) => {     
          if (d.assignedTo === name) {
            const meetingsAssigned: any[] = await getNextQueue(
              service.code,
              service.areaCode,
              name,
              shared
            )
              .then((response) => {
                if(response) return response;
                throw new Error(t("home.error.queue"));
              })
              .catch((error) => console.error(error));
            return meetingsAssigned
              ? meetingsAssigned.filter(function (item: any, pos: number) {
                  return (
                    meetingsAssigned.findIndex(
                      (_) => _.meetingID == item.meetingID
                    ) == pos
                  );
                })
              : [];
          }
        };

        try {
          for (let i = 0; i < data.length; i++) {
            const d = data[i];
            if (callback) {
              const meetingsAssigned: any[] =
                (await callback(d, service)) || [];
              const filteredMeetings = meetingsAssigned.filter(
                (meeting) =>
                  !totalMeetingsAssigned.find(
                    (_) => _.meetingID == meeting.meetingID 
                  )
              );

              const expandedMeetings = await Promise.all(
                filteredMeetings.map(async (_) => {
                  const expandMeeting = await getEvent(_.meetingID, token);
                  return { ...expandMeeting, ..._,  };
                })
              );

              const expandedMeetingsFiltered = expandedMeetings.filter(
                (_) =>
                  _.status != EnumMeetingStatus.CANCELLED &&
                  _.status != EnumMeetingStatus.FINALIZED
              );
              totalMeetingsAssigned = [
                ...totalMeetingsAssigned,
                ...expandedMeetingsFiltered,
              ];
            }
          }
        } catch (err) {
          console.error(err);
        }
      })
    );

    totalMeetingsAssigned = totalMeetingsAssigned.filter(
      (meeting, index) =>
        index ==
        totalMeetingsAssigned.findIndex((_) => _.meetingID == meeting.meetingID)
    );
   
    

    props.setMeetingsAssignedList(totalMeetingsAssigned); //importante
  };

  const refreshMeetingAssigned = async () => {
    setIsLoading(true);
    const { backoffice, shared } = await authContext.getTokenForScopes();
    const account: AuthorizationModel = await authContext
      .getAccount()
      .then((response) => JSON.parse(response));
    const name = account.profile.code;
    try {
      const responseServices: Response = await getUserServices(
        name,
        backoffice
      );
      if (!responseServices.ok) throw new Error(t("home.error.userServices"));
      const dataServices: UserService[] = await responseServices.json();

      updateMeetingAssigned(dataServices); 
      let data: renderList[] = await getDataServicesWithQueue(dataServices);
      setServicesWithQueue(data);
      setFinish(true);
      setServices(dataServices);
     
    } catch (error) {
     toast((error), { type: "error"});
     console.error(error)
    }

    setIsLoading(false);
  };

  const setServiceFromSelect = async (selected: any) => {
    await props.setService((prevService: any) => {
      prevService = selected?.key;

      return prevService;
    });
  };

  const getSelectService = async (service: UserService) => {
    const serviceId = service.code;
    const areaCode = service.areaCode;
    await setServiceFromSelect(serviceId);

    props.serviceSelected.current = serviceId;
    props.setService(serviceId);
    props.setUsersArea(areaCode);

    props.setTypeButtonList(TypeButtonList.DASHBOARD);
    try {
      const response: any = await getQueueData(serviceId, areaCode);
      if (response && response.length > 0) {
        const account: AuthorizationModel = await authContext
          .getAccount()
          .then((response) => JSON.parse(response));
        const name = account.profile.code;

        setQueue(response);
        response.map(async (queue: Queue) => {
          if (queue.isVisibleToServants)
            props.setQueueCounter((queueCounter: number) => queueCounter + 1);
        });

        props.setUserList(response, serviceId);
      }
    } catch (error) {
      toast((error), { type: "error"});
  
    }
  };

  return (
    <Screen
      services={services}
      finish={finish}
      service={props.service}
      refreshServicesList={refreshServicesList}
      servicesWithQueue={servicesWithQueue}
      getSelectService={getSelectService}
      isLoading={isLoading}
    />
  );
};
