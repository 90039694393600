import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserServices } from "../../../../assets/api";
import { AuthContext } from "../../../../context/AuthContext";
import { AuthorizationModel } from "../../../../models/Authorization";
import { UserService } from "../../Dashboard";
import Screen from "./Screen";
interface Props {
  meetings: any[];
  contentMeeting?: (string | any)[];
  servicesRefresh?: () => void;
  onClickMeeting?: (x: any) => void;
  disabledMeeting?: (x: any) => boolean;
  updateMeetingAssigned: (x: any) => void;
}

const AssignedMeetings =(props: Props) => {
  
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const meetings = props.meetings.map((_) => {
    _["start"] = _["meetingStart"];
    _["code"] = _["meetingID"];
   

    return _;
  });
 
  const updateMeetingAssigned = async () => {
   
    const { backoffice, shared } = await authContext.getTokenForScopes();
    const account: AuthorizationModel = await authContext
      .getAccount()
      .then((response) => JSON.parse(response));

     
    const name = account.profile.code;
    const responseServices: Response = await getUserServices(name, backoffice);
   
    if (!responseServices.ok) throw new Error(t("home.error.userServices"));
    const dataServices: UserService[] = await responseServices.json();

    props.updateMeetingAssigned(dataServices);
 
   
  };
  return (
    
 
    <Screen
      {...props}
      meetings={meetings}
      servicesRefresh={updateMeetingAssigned}
      loading={loading} 
    />
    
  );
};

export default AssignedMeetings;