import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BOTableMeeting from "../../../../components/ServicesDetails/BOServiceMeeting/BOTableMeeting";
import EmptyStateMeeting from "../EmptyStateMeeting";
import { Loader } from "semantic-ui-react";
interface Props {
  meetings: any[];
  loading: boolean;
  contentMeeting?: (string | any)[];
  servicesRefresh?: () => void;
  onClickMeeting?: (x: any) => void;
  disabledMeeting?: (x: any) => boolean;
}


export default (props: Props) => {


  const { t } = useTranslation();
  return (
    <div className="mb-4" style={{minHeight: '150px', position: 'relative' }}>
      <h2>{t("home.assigned_meetings")}</h2>
      <Loader size="large" active={props.loading} style = {{marginTop: '50px'}}>
          {t("loading.defect")}
        </Loader>
      {props.meetings.length > 0 ? (
        <BOTableMeeting {...props} />
      ) : (
        <EmptyStateMeeting />
      )}
    </div>
  );
  
};
